<template>
  <d-button
    :class="isExporting ? 'disabled' : ''"
    :icon="isExporting ? 'fa fa-spinner fa-spin' : 'fa fa-external-link'"
    :text="$store.getters['layout/getInnerWidth'] >= 991 ? label : ''"
    class="d-btn btn d-btn-default font-text-title"
    @on:button-click="exportToCSV"
  />
</template>
<script>
import {exportClients} from "@api/doinsport/services/client/client.api";
import {exportPayments} from "@api/doinsport/services/payments/payments.api";
import {exportBookings} from "@api/doinsport/services/bookings/booking.api";
import {exportSubscribers} from "@api/doinsport/services/subscription-card/subscription-card.api";
import {DAY_SLASH_MONTH_SLASH_YEAR} from "@/utils/date";

export default {
  data: () => ({
    isExporting: false,
  }),
  props: {
    target: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: 'general.actions.export',
    }
  },
  methods: {
    exportToCSV() {
      if (false === this.isExporting) {
        this.isExporting = true;

        this.getTargetApiLink()
          .then((response) => {
            this.$downloadCSV(response.data, this.getFileName());
          })
          .finally(() => {
            this.isExporting = false;
          })
        ;
      }
    },
    getFileName() {
      let fileName = this.$route.name;

      if (this.target === 'subscribers') {
        fileName = this.$t('views.dashboard.content.achievements.subscribers') +
          '_' +
          this.$store.getters["auth/currentClub"].name +
          '_' +
          this.$moment().format(DAY_SLASH_MONTH_SLASH_YEAR)
        ;
      }

      return fileName;
    },
    getTargetApiLink() {
      switch (this.target) {
        case 'clients':
          return exportClients();
        case 'payments':
          return exportPayments();
        case 'subscribers':
          return exportSubscribers();
        case 'bookings':
          return exportBookings();
        default:
          console.error('unknown export file');
      }
    }
  }
}

</script>
