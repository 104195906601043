import {hydrate} from "@/utils/form";
import {toFloatFixed} from "@/utils/string";
import {formatRow} from "@/utils/classes";
import {currency} from "@/utils/currency";
import {toTimezoneCustomFormat} from "@/utils/timezone";
import {DAY_SLASH_MONTH_SLASH_YEAR} from "@/utils/date";

export default class Payout {
  id = null;
  description = null;
  destinationName = null;
  amount = null;
  reference = null;
  status = null;
  createdAt = null;
  arrivalDate = null;
  reportRunUrl = null;

  constructor(object, options) {
    if ("undefined" !== typeof options) {
      if (options.row) {
        this.formatToRow(object);
      }
      if (options.serialize) {
        this.serialize(object);
      }
    }
  }

  serialize(object) {
    hydrate(this, object);
  }

  formatToRow(object) {
    hydrate(this, object);
    formatRow(this);
    this.updateRow(this);
  }

  updateRow(row) {
    for (const key of Object.keys(row)) {
      row[key] = null === row[key] ? '-' : row[key];
    }

    row.payoutAmount = toFloatFixed(row.amount) + ' ' +currency();
    row.payoutCreatedAt = toTimezoneCustomFormat(row.createdAt, DAY_SLASH_MONTH_SLASH_YEAR);
    row.payoutArrivalDate = toTimezoneCustomFormat(row.arrivalDate, DAY_SLASH_MONTH_SLASH_YEAR);
  }
}
