import {hydrate} from "@/utils/form";

const STRIPE_PROVIDER = 'stripe';
const CARD_PROVIDER = 'card';
const CAPTURE_AUTO = 'automatic';
const CAPTURE_MANUEL = 'manual';
const SEPA_PROVIDER = 'sepa_debit';
const PAYMENT_TOKEN_PROVIDER = 'payment_token';
const WALLET_PROVIDER = 'wallet';
const ORANGE_MONEY_PROVIDER = 'orange_money';
const ADMIN_PROVIDER = 'administration';

export default class Payment {
  client = null;
  amount = null;
  provider = null;
  method = null;
  metadata = {};
  currency = null;
  club = null;
  cart = {};

  constructor(object = null) {
    if (object) {
      this.serialize(object);
    }
  }

  serialize(object) {
    hydrate(this, object);
  }

  paymentMethod(payment) {
    let paymentMode = null;

    switch (payment.provider) {
      case STRIPE_PROVIDER:
        switch (payment.method) {
          case CARD_PROVIDER:
            if (payment.captureMethod === CAPTURE_AUTO) {
              paymentMode = 'views.payment.content.full-infos.payment-mode.card-stripe';
            } else if (payment.captureMethod === CAPTURE_MANUEL) {
              paymentMode = 'views.payment.content.full-infos.payment-mode.bank-imprint';
            }
            else {
              paymentMode = 'views.payment.content.full-infos.payment-mode.card-stripe';
            }
            break;
          case SEPA_PROVIDER:
            paymentMode = 'views.payment.content.full-infos.payment-mode.sepa-stripe';
        }
        break;
      case PAYMENT_TOKEN_PROVIDER:
        paymentMode = 'views.payment.content.full-infos.payment-mode.credit';
        break;
      case WALLET_PROVIDER:
        paymentMode = 'views.payment.content.full-infos.payment-mode.wallet';
        break;
      case ORANGE_MONEY_PROVIDER:
        paymentMode = 'views.payment.content.full-infos.payment-mode.orange-money';
        break;
      case ADMIN_PROVIDER:
        paymentMode = 'views.payment.content.full-infos.payment-mode.back-office';
        break;
      default:
        console.error('unknown payment method', payment);
        paymentMode = 'views.payment.content.full-infos.payment-mode.unknown';
    }

    return paymentMode;
  }

}

