<template>
  <div
    :class="!defaultUse ? '' : 'card card-body'"
  >
    <b-row
      v-if="defaultUse || clientId === null"
      class="row-style"
      no-gutters
    >
      <b-col
        class="mt-3 ml-3"
        cols="6"
      >
        <d-body-nav-bar
          :tabs="tabs"
          @tab-changed="onTabChanged"
        />
      </b-col>
      <b-col
        v-if="selectedTab === null"
        class="mt-3 mr-2"
        align="right"
      >
        <export-csv
          v-if="!(!defaultUse && clientId)"
          target="payments"
        />
      </b-col>
      <b-col
        v-if="selectedTab === 'due-date' && clubWithBankConfig"
        class="mt-3 mr-2"
        align="right"
      >
        <d-button
          :icon="'fa fa-external-link'"
          :text="$store.getters['layout/getInnerWidth'] >= 991 ? 'general.actions.export' : ''"
          class="d-btn btn d-btn-default font-text-title"
          @on:button-click="displayDueDateModal = !displayDueDateModal"
        />
      </b-col>
      <b-col
        class="mt-3 mr-2"
      >
        <div
          class="has-search"
          @click="displayFromDateCalendar = !displayFromDateCalendar"
        >
          <span class="input-date-picker calendar-size fa fa-calendar form-control-feedback"></span>
          <input
            :value="fromDateLabel"
            type="text"
            class="background-light-blue-inputs form-control pointer text-center"
            readonly
          >
        </div>
      </b-col>
      <b-col
        class="mt-3 mr-2"
      >
        <div
          class="has-search"
          @click="displayUntilDateCalendar = !displayUntilDateCalendar"
        >
          <span class="input-date-picker calendar-size fa fa-calendar form-control-feedback"></span>
          <input
            :value="untilDateLabel"
            type="text"
            class="background-light-blue-inputs form-control pointer text-center"
            readonly
          >
        </div>
      </b-col>
      <b-col
        v-if="selectedTab !== 'transfers'"
        class="mt-3 mr-3"
        cols="2"
      >
        <div class="has-search">
          <span class="fa fa-search form-control-feedback"></span>
          <input
            v-model="filter"
            :readonly="selectedTab === 'transfers'"
            :placeholder="$t('components.doinsport.table.search')"
            class="form-control"
            type="text"
            @keyup="manualFilter"
          >
        </div>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col
        cols="12"
      >
        <d-table
          v-if="selectedTab === 'due-date'"
          :items="dueDateItems"
          :tablefields="dueDateFields"
          :totalRows="dueDateTotalRows"
          :per-page="dueDateItemsPerPage"
          :per-page-enabled="true"
          :is-busy="isBusyDueDate"
          @per-page:update="onPerPageDueDateUpdate"
          @on:pagination-model:update="onDueDatePageUpdate"
          @on:sort-by:item="onDueDateSort"
        />
        <d-table
          v-else-if="selectedTab === 'transfers'"
          :items="payoutItems"
          :tablefields="payoutFields"
          :totalRows="payoutTotalRows"
          :per-page="payoutItemsPerPage"
          :per-page-enabled="true"
          :is-busy="isBusyPayout"
          @per-page:update="onPerPagePayoutUpdate"
          @on:pagination-model:update="onPayoutPageUpdate"
          @on:sort-by:item="onPayoutSort"
        />
        <d-table
          v-else
          :tablefields="clientId ? customerTableFields : tableFields"
          :items="items"
          :totalRows="totalRows"
          :per-page="itemsPerPage"
          :is-busy="isBusy"
          :showPagination="true"
          :per-page-enabled="clientId === null"
          @on:pagination-model:update="onPageUpdate"
          @per-page:update="onPerPageUpdate"
        />
      </b-col>
    </b-row>
    <d-calendar-modal
      identifier="fromDate"
      :show="displayFromDateCalendar"
      :filterAfterDate="selectedTab === 'transfers' ? new Date() : null"
      @on:calendar-change="onFromDateChange"
    />
    <d-calendar-modal
      identifier="untilDate"
      :show="displayUntilDateCalendar"
      :filterFromDate="selectedTab === 'transfers' ? null : fromDate"
      :filterAfterDate="selectedTab === 'transfers' ? new Date() : null"
      @on:calendar-change="onUntilDateChange"
    />
    <export-due-date-modal
      :display="displayDueDateModal"
      :from-date="startDate"
      :to-date="endDate"
    />
  </div>
</template>
<script>

import {getPaymentByClientId, getPayments} from "@api/doinsport/services/payments/payments.api";
import Payment from "@/classes/doinsport/Payment";
import ExportCsv from "@custom/ExportCsv";
import {lowercaseFirstLetter, toFloatFixed} from "@/utils/string";
import {getPayouts} from "@api/doinsport/services/stripe/payout/payout.api";
import Payout from "@/classes/doinsport/Payout";
import {getSubscriptionCardsDueDates} from "@api/doinsport/services/subscription-card/subscription-card.api";
import {DAY_SLASH_MONTH_SLASH_YEAR} from "@/utils/date";
import ExportDueDateModal from "@custom/payment/ExportDueDateModal";

export default {
  components: {ExportDueDateModal, ExportCsv},
  props: {
    client: {
      type: Object,
      default: () => {
      }
    },
    containerClass: {
      type: String,
      default: ''
    },
    clientId: {
      type: String,
      default: null
    },
    defaultUse: {
      type: Boolean,
      default: true
    },
  },
  data: () => ({
    page: 1,
    firstLoad: true,
    payoutPage: 1,
    displayDueDateModal: false,
    dueDatePage: 1,
    items: [],
    payoutItems: [],
    dueDateItems: [],
    filter: '',
    lastOrder: '',
    timer: null,
    totalRows: 1,
    payoutTotalRows: 1,
    dueDateTotalRows: 1,
    isBusy: false,
    isBusyPayout: false,
    isBusyDueDate: false,
    searchInput: 1,
    inputSearch: '',
    startDate: null,
    itemsPerPage: 10,
    payoutItemsPerPage: 10,
    dueDateItemsPerPage: 10,
    endDate: null,
    fromDate: null,
    untilDate: null,
    selectedTab: null,
    displayFromDateCalendar: false,
    displayUntilDateCalendar: false,
  }),
  mounted() {
    this.selectedTab = null;

    for (const tab of this.$store.getters['menu/getTabs'].paymentIndex) {
      tab.active = tab.name === 'views.payment.content.full-infos.all';
    }
    this.startDate = (this.$moment().date(1).hour(0).minute(0).second(0)).subtract(1, 'years');
    this.endDate = this.$moment().endOf('month').hour(23).minute(59).second(59);
    this.loadData();
  },
  watch: {
    'filter': function () {
      this.loadSelectedTabData();
    }
  },
  computed: {
    tabs() {
      return this.$store.getters['menu/getTabs'].paymentIndex;
    },
    clubWithBankConfig() {
      return this.$store.getters["auth/currentClub"].bankConfiguration !== null;

    },
    alignCellsToLeft() {
      return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center' : 'text-left';
    },
    fromDateLabel() {
      return this.$t('views.payment.content.full-infos.from') + ' ' + this.$moment(this.startDate).format('DD-MM-YYYY');
    },
    untilDateLabel() {
      return this.$t('views.payment.content.full-infos.until') + ' ' + this.$moment(this.endDate).format('DD-MM-YYYY');
    },
    dueDateFields() {
      return [
        {
          key: "dateDueDate",
          label: this.$t("components.custom.due-date.date"),
          sortable: true,
        },
        {
          key: "customerFullName",
          label: this.$t('views.shop.sales-list.client'),
          sortable: true,
        },
        {
          key: "cardName",
          label: this.$t('components.form.playground.block-price.step-1.subscription-rates.subscription-label'),
          sortable: true,
        },
        {
          key: "amount",
          label: this.$t("views.dashboard.content.payment.tabs.amount"),
          sortable: true,
          class: this.alignCellsToLeft
        },
        {
          key: "iban",
          label: this.$t('views.client.details.body.left.subscription-affect.iban-label'),
          sortable: true,
          class: this.alignCellsToLeft
        },
      ]
    },
    payoutFields() {
      return [
        {
          key: "payoutAmount",
          label: this.$t("views.dashboard.content.payment.tabs.amount"),
          sortable: true,
        },
        {
          key: "payoutDestinationName",
          label: this.$t("views.dashboard.content.payment.tabs.destination-name"),
          sortable: true,
          class: this.alignCellsToLeft
        },
        {
          key: "payoutDescription",
          label: this.$t("views.dashboard.content.payment.tabs.description"),
          sortable: true,
          class: this.alignCellsToLeft
        },
        {
          key: "payoutStatus",
          label: this.$t("views.dashboard.content.payment.tabs.payout-status"),
          sortable: true,
          class: this.alignCellsToLeft
        },
        {
          key: "payoutCreatedAt",
          label: this.$t("views.dashboard.content.payment.tabs.payout-created-at"),
          sortable: true,
          class: this.alignCellsToLeft
        },
        {
          key: "payoutArrivalDate",
          label: this.$t("views.dashboard.content.payment.tabs.payout-arrival-date"),
          sortable: true,
          class: this.alignCellsToLeft
        },
        {
          key: "payoutReportRunUrl",
          class: "text-center",
          label: this.$t("views.dashboard.content.payment.tabs.bill"),
          sortable: false
        },
      ];
    },
    tableFields() {
      return [
        {key: "firstName", label: this.$t("views.dashboard.content.payment.tabs.firstName"), sortable: true},
        {key: "lastName", label: this.$t("views.dashboard.content.payment.tabs.lastName"), sortable: true},
        {key: "type", label: this.$t("views.dashboard.content.payment.tabs.type"), sortable: false},
        {key: "dueTo", label: this.$t("views.dashboard.content.payment.tabs.dueTo"), sortable: false},
        {key: "date", label: this.$t("views.dashboard.content.payment.tabs.date"), sortable: false},
        {key: "totalAmount", label: this.$t("views.dashboard.content.payment.tabs.totalAmount"), sortable: false},
        {
          key: "paymentStatus",
          class: this.alignCellsToLeft,
          label: this.$t("views.dashboard.content.payment.tabs.paymentStatus"),
          sortable: false
        },
        {
          key: "bill",
          class: "text-center",
          label: this.$t("views.dashboard.content.payment.tabs.bill"),
          sortable: false
        },
      ];
    },
    customerTableFields() {
      return [
        {key: "type", label: this.$t("views.dashboard.content.payment.tabs.type"), sortable: false},
        {key: "dueTo", label: this.$t("views.dashboard.content.payment.tabs.dueTo"), sortable: false},
        {key: "date", label: this.$t("views.dashboard.content.payment.tabs.date"), sortable: false},
        {key: "totalAmount", label: this.$t("views.dashboard.content.payment.tabs.totalAmount"), sortable: false},
        {
          key: "paymentStatus",
          class: this.alignCellsToLeft,
          label: this.$t("views.dashboard.content.payment.tabs.paymentStatus"),
          sortable: false
        },
        {
          key: "bill",
          class: "text-center",
          label: this.$t("views.dashboard.content.payment.tabs.bill"),
          sortable: false
        },
      ];
    },
  },
  methods: {
    getPayoutSortBy() {
      let sortBy = '';

      if (null !== this.lastOrder && '' !== this.lastOrder) {
        sortBy = lowercaseFirstLetter(this.lastOrder.sortBy.replace('payout', ''));

        return this.lastOrder.sortDesc ? '&order[' + sortBy + ']=desc' : '&order[' + sortBy + ']=asc';
      }

      return '';
    },
    getDueDateSortBy() {
      if (null !== this.lastOrder && '' !== this.lastOrder) {
        return this.lastOrder.sortDesc ? '&order[' + this.lastOrder.sortBy + ']=desc' : '&order[' + this.lastOrder.sortBy + ']=asc';
      }

      if (this.lastOrder === '') {
        return '&order[date]=asc';
      }

      return '';
    },
    onDueDateSort(item) {
      if (item.sortBy !== '') {
        this.lastOrder = item;

        switch (item.sortBy) {
          case 'dateDueDate':
            this.lastOrder.sortBy = 'date';
            break;
          case 'customerFullName':
            this.lastOrder.sortBy = 'client.lastName';
            break;
          case 'cardName':
            this.lastOrder.sortBy = 'card.name';
            break;
          case 'amount':
            this.lastOrder.sortBy = 'amount';
            break;
        }
        this.loadSubscriptionsDueDates();
      }
    },
    onPayoutSort(item) {
      if (item.sortBy !== '') {
        this.lastOrder = item;
        this.loadPayouts();
      }
    },
    paymentMethods(item) {
      const payment = new Payment();
      const paymentMethod = payment.paymentMethod(item);

      return this.$t(paymentMethod);
    },
    manualFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.search(this.filter);
      }, 400);
    },
    search(filter) {
      this.inputSearch = filter;
      this.loadData();
    },
    onPageUpdate(page) {
      this.page = page;
      this.loadData();
    },
    onPayoutPageUpdate(page) {
      this.payoutPage = page;
      this.loadPayouts();
    },
    onDueDatePageUpdate(page) {
      this.dueDatePage = page;
      this.loadSubscriptionsDueDates();
    },
    onPerPageUpdate(itemsPerPage) {
      this.itemsPerPage = itemsPerPage;
      this.page = 1;
      this.loadData();
    },
    onPerPagePayoutUpdate(itemsPerPage) {
      this.payoutItemsPerPage = itemsPerPage;
      this.payoutPage = 1;
      this.loadPayouts();
    },
    onPerPageDueDateUpdate(itemsPerPage) {
      this.dueDateItemsPerPage = itemsPerPage;
      this.dueDatePage = 1;
      this.loadSubscriptionsDueDates();
    },
    onFromDateChange(changedDate) {
      this.fromDate = changedDate;
      this.startDate = this.$moment(this.fromDate).hour(0).minute(0).second(0);
      this.loadSelectedTabData();
    },
    onUntilDateChange(changedDate) {
      this.untilDate = changedDate;
      this.endDate = this.$moment(this.untilDate).hour(23).minute(59).second(59);
      this.loadSelectedTabData();
    },
    loadSelectedTabData() {
      switch (this.selectedTab) {
        case 'transfers':
          this.loadPayouts();
          break;
        case 'due-date':
          this.loadSubscriptionsDueDates();
          break;
        default:
          this.loadData();
      }
    },
    getPayoutFilters() {
      let payoutFilters = '';

      if (this.startDate) {
        payoutFilters += '&createdAt[after]=' + this.$moment(this.startDate).format('YYYY-MM-DD 00:00:00');
      }

      if (this.endDate) {
        payoutFilters += '&createdAt[before]=' + this.$moment(this.endDate).format('YYYY-MM-DD 23:59:59');
      }

      return payoutFilters + this.getPayoutSortBy();
    },
    getDueDateFilters() {
      let dueDateFilters = '';

      if (this.startDate) {
        dueDateFilters += '&date[after]=' + this.$moment(this.startDate).format('YYYY-MM-DD');
      }

      if (this.endDate) {
        dueDateFilters += '&date[before]=' + this.$moment(this.endDate).format('YYYY-MM-DD');
      }

      return dueDateFilters + this.getDueDateSortBy();
    },
    loadPayouts() {
      this.isBusyPayout = true;
      this.payoutItems = [];

      getPayouts(this.payoutPage, this.payoutItemsPerPage, this.getPayoutFilters())
        .then((response) => {
          this.payoutTotalRows = response.data['hydra:totalItems'];

          for (const item of response.data['hydra:member']) {
            this.payoutItems.push(new Payout(item, {row: true}));
          }
        })
        .finally(() => {
          this.isBusyPayout = false;
        })
      ;
    },
    loadSubscriptionsDueDates() {
      this.isBusyDueDate = true;
      this.dueDateItems = [];

      getSubscriptionCardsDueDates(this.dueDateItemsPerPage, this.dueDatePage, this.inputSearch, this.getDueDateFilters())
        .then((response) => {
          this.dueDateTotalRows = response.data['hydra:totalItems'];

          for (const item of response.data['hydra:member']) {
            const dueDateObject = {
              dateDueDate: this.$moment(item.date).format(DAY_SLASH_MONTH_SLASH_YEAR),
              amount: toFloatFixed(item.amount) + ' ' + this.$currency,
              iban: item.client.rib ? item.client.rib.iban : '-',
              customerFullName: item.client.fullName,
              cardName: item.card.name
            };
            this.dueDateItems.push(dueDateObject);
          }
        })
        .finally(() => {
          this.isBusyDueDate = false;
        })
      ;
    },
    loadData() {
      this.isBusy = true;

      if (this.clientId) {
        getPaymentByClientId(this.clientId, 10, this.page)
          .then(response => {
            this.items = [];
            this.totalRows = response.data['hydra:totalItems'];

            response.data['hydra:member'].forEach(item => {
              const itemPayment = item.cart.items[0];

              this.items.push({
                firstName: item.client.firstName,
                lastName: item.client.lastName,
                type: this.paymentMethods(item),
                dueTo: this.$t("views.dashboard.content.payment.dueTo." + itemPayment.productType),
                date: this.$toTimezone(item.createdAt).format('DD-MM-YYYY HH:mm'),
                totalAmount: this.getTotalAmount(item),
                paymentStatus: item,
                paymentMode: ((item.method === 'card') ? "CARD" : "SEPA") + "source",
                bill: item.invoiceUrl,
              });
            });
          })
          .finally(() => {
            this.isBusy = false;
          })
        ;
      } else {
        getPayments(this.selectedTab, {
          startDate: this.startDate.format(),
          endDate: this.endDate.format()
        }, this.itemsPerPage, this.page, this.inputSearch, this.clientId)
          .then(response => {
            this.items = [];
            this.totalRows = response.data['hydra:totalItems'];

            response.data['hydra:member'].forEach(item => {
              const itemPayment = item.cart.items[0];

              this.items.push({
                firstName: item.client.firstName,
                lastName: item.client.lastName,
                type: this.paymentMethods(item),
                dueTo: this.$t("views.dashboard.content.payment.dueTo." + itemPayment.productType),
                date: this.$toTimezone(item.createdAt).format('DD-MM-YYYY HH:mm'),
                totalAmount: this.getTotalAmount(item),
                paymentStatus: item,
                paymentMode: ((item.method === 'card') ? "CARD" : "SEPA") + "source",
                bill: item.invoiceUrl,
              });
            });
          })
          .finally(() => {
            this.isBusy = false;
          })
        ;
      }
    },
    getTotalAmount(item) {
      if (item.paymentTokenOperationDetail === null) {
        return toFloatFixed(item.amount) + ' ' + this.$currency;
      } else {
        return item.paymentTokenOperationDetail.amount + ' x ' + item.paymentTokenOperationDetail.name;
      }
    },
    onTabChanged(event) {
      switch (event.name) {
        case 'views.payment.content.full-infos.sepa-withdrawals':
          this.selectedTab = 'sepa_debit';
          break;
        case 'views.payment.content.full-infos.onlinePayment':
          this.selectedTab = 'card';
          break;
        case 'views.payment.content.full-infos.all':
          this.selectedTab = null;
          break;
        case 'views.payment.content.full-infos.disputed-failed':
          this.selectedTab = 'disputed-failed';
          break;
        case 'views.payment.content.full-infos.transfers':
          this.selectedTab = 'transfers';
          break;
        case 'views.client.details.body.left.subscription-tab.due-date-label':
          this.selectedTab = 'due-date';
          break;
        default:
          this.selectedTab = null;
          break;
      }
      this.startDate = (this.$moment().date(1).hour(0).minute(0).second(0)).subtract(1, 'years');
      this.endDate = this.$moment().endOf('month').hour(23).minute(59).second(59);

      if (this.selectedTab === 'transfers') {
        this.loadPayouts();
      } else if (this.selectedTab === 'due-date') {
        this.startDate = this.$moment().startOf('month').format('YYYY-MM-DD');
        this.endDate = this.$moment().endOf('month').format('YYYY-MM-DD');
        this.loadSubscriptionsDueDates();
      } else {
        this.loadData();
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/_b-card.scss";

.calendar-size {
  font-size: 20px;
}

.calendar-size.fa-calendar {
  color: #0c5460
}

.input-date-picker .form-control:disabled, .form-control[readonly] {
  background-color: #EAF2F7;
  opacity: 1;
}

/deep/ .has-search .form-control-feedback {
  z-index: 0;
}

.col-1-5 {
  max-width: 11.333333%;
}

.col-5-6 {
  flex: 0 0 44%;
  max-width: 44%;
}
</style>
