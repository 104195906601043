<template>
  <b-modal
    :id="modalId"
    :body-bg-variant="'gray-lighten'"
    hide-header
    hide-footer
    hide-header-close
    header-class="my-second-class"
  >
    <b-row>
      <b-col align="left" class="modal-title-class">
        {{ $t('components.custom.due-date.title') }}
      </b-col>

      <b-col class="mt-3" cols="12">
        {{ $t('components.custom.due-date.description') }}
      </b-col>

      <b-col class="mt-4" cols="6">
        {{ $t('components.custom.due-date.select-date') }}
      </b-col>
      <b-col class="mt-3" cols="6">
        <d-inline-calendar
          :model="selectedDate"
          :disabled-date="(date) => date < $moment(toDate).add('-1', 'days')"
          @on:select="selectedDate = $event"
        />
      </b-col>
    </b-row>
    <b-row
      no-gutters
      class="mt-3"
    >
      <b-col
        :align="selectedDate ? 'right' : 'middle'"
      >
        <d-button
          text="general.actions.dismiss"
          class="d-btn btn d-btn-danger font-text-title"
          @on:button-click="$bvModal.hide(modalId)"
        />
      </b-col>
      <b-col
        v-if="selectedDate"
        class="ml-2"
        align="left"
      >
        <d-button
          text="general.actions.validate"
          class="d-btn btn d-btn-primary font-text-title"
          @on:button-click="onValidate"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>

import {exportDueDate} from "@api/doinsport/services/subscription-card/subscription-card.api";

export default {
  data: () => ({
    selectedDate: null,
    active: false,
    componentFormKey: 1,
  }),
  props: {
    modalId: {
      type: String,
      default: 'default'
    },
    title: {
      type: String,
      default: ''
    },
    fromDate: {
      type: String,
      default: ''
    },
    toDate: {
      type: String,
      default: ''
    },
    display: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    display: function () {
      this.active = false;
      this.selectedDate = null;
      this.componentFormKey += 1;
      this.$bvModal.show(this.modalId);
    }
  },
  methods: {
    onValidate() {
      const debitDate = this.$moment(this.selectedDate).format('YYYY-MM-DD');
      const startingDate = this.$moment(this.fromDate).format('YYYY-MM-DD');
      const endingDate = this.$moment(this.toDate).format('YYYY-MM-DD');

      exportDueDate(startingDate, endingDate, debitDate)
        .then((response) => {
          this.$downloadXML(response.data, 'DueDate_' + debitDate);
        })
        .finally(() => this.$bvModal.hide(this.modalId))
      ;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/_modal.scss";
</style>
