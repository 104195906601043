import {_get, _post, _put, clubId} from "@api/doinsport/services/httpService";

export const STATUS = {
    pending: ''
};

const URL = 'payments';

const moment = require('moment');

export const get = (iri) => _get(iri);

export const getPaymentByClientId = (clientId, itemsPerPage = 10, page = 1) =>
  _get(
    URL +
    '?client.id=' + clientId
    + '&itemsPerPage=' + itemsPerPage +
    '&page=' + page
  );

export const exportPayments = () =>
  _get(
    URL +
    '.export' +
    '?order[createdAt]=asc' +
    '&club.id=' + clubId
  )
;

export const getPayments = (method, dates, itemsPerPage, page, search, status, clientId) => {
    let params = '?client.club.id=' + clubId;
    if (method) {
      if (method === 'disputed-failed') {
        params = params + '&status[]=failed&status[]=disputed' + '&method=sepa_debit';
      } else {
        params = params + '&method=' + method;
      }
    }
    if (itemsPerPage && page) {
      params = params + '&itemsPerPage=' + itemsPerPage + '&page=' + page;
    }
    if (search) {
        params = params + '&search=' + search;
    }
    if (clientId) {
        params = params + '&client.id=' + clientId;
    }
    if (status) {
        params = params + '&status=' + status;
    }
    if (dates) {
         params = params + '&createdAt[after]=' + moment(dates.startDate).format('YYYY-MM-DD 00:00:00');
         params = params + '&createdAt[before]=' + moment(dates.endDate).format('YYYY-MM-DD 23:59:59');
    }
    return _get(URL + params)
};

export const addCart = (data) => _post(URL + '/carts', data);

export const addPayment = (data) => _post(URL, data);
